import React from 'react'

import { Button } from '@farewill/ui'
import type { ButtonProps } from '@farewill/ui/components/Button/types'
import { TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL } from 'config'

const CallToAction = ({
  children,
  tag: Tag = Button.Primary,
  trackingId,
  ...props
}: ButtonProps & {
  children: string
  trackingId: string
  tag?: React.FunctionComponent<React.PropsWithChildren<ButtonProps>>
}): React.ReactElement => (
  <Tag
    data-track-event="click"
    data-track-element="cta"
    data-track-slice={trackingId}
    href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL} // TODO: Add page-specific query params
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Tag>
)

export default CallToAction
