import React from 'react'
import styled from 'styled-components'
import { H, P, Grid, Image, Wrapper } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screen, screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { PProps } from '@farewill/ui/components/Text/Paragraph/types'

import PreventOrphan from 'components/PreventOrphan'
import { AWARD_LOGO_URL } from 'lib/awards/constants'

const awardImagePaths = {
  signature: AWARD_LOGO_URL.WILL_WRITING_FIRM_OF_THE_YEAR_SIGNATURE,
  decorative: AWARD_LOGO_URL.WILL_WRITING_FIRM_OF_THE_YEAR_DECORATIVE,
  decorativeColor:
    AWARD_LOGO_URL.WILL_WRITING_FIRM_OF_THE_YEAR_DECORATIVE_COLOR,
  customerCareExcelence:
    AWARD_LOGO_URL.CUSTOMER_CARE_EXCELLENCE_DECORATIVE_COLOR,
}

const StyledImageWrapperDecorative = styled(Wrapper)`
  width: 100%;
  ${screenMin.m`
    display: none;
    `}
`

const StyledImageWrapperSignature = styled(Wrapper)`
  display: flex;

  ${screenMax.s`
    display: none;
  `};

  ${screen.m`
    width: 120%;
    margin: 0 0 0 -${GTR.M};
  `};
`

const StyledGrid = styled(Grid)`
  align-self: center;

  ${screenMin.l`
    padding-top: ${GTR.M};
    padding-bottom: 0;
    `}
`
const StyledGridItem = styled(Grid.Item)`
  align-self: center;
`

const StyledImage = styled(Image)`
  ${(props) => props.stretch && `height: 100%; max-height: ${props.height}px;`}
`

type AwardBannerProps = {
  title?: string
  paragraph?: string
  paragraphs?: string[]
  paragraphColor?: PProps['color']
  paragraphSize?: PProps['size']
  showDecorativeColor?: boolean
}

const AwardBanner = ({
  title = 'National Will Writing Firm of the Year, 4 years in a row',
  paragraph = 'For generations, writing a will has been a slow, expensive and inconvenient process that usually involves seeing a solicitor. We created Farewill to change that and have since helped tens of thousands of people make a will across the UK. Every year since 2019 we’ve been crowned National Will Writing Firm of the Year at the British Wills and Probate Awards.',
  paragraphs = [paragraph],
  paragraphColor = COLOR.BLACK,
  paragraphSize = 'M',
  showDecorativeColor = false,
}: AwardBannerProps): React.ReactElement => (
  <StyledGrid container>
    <StyledGridItem spanFromM={7} maxWidthInColumns={7}>
      <H
        margin={[0, 0, 'S']}
        maxWidthInColumns={6}
        decorative
        size="L"
        tag="h2"
      >
        {title}
      </H>
      {paragraphs.map((p, index) => {
        const isLast = index === paragraphs.length - 1
        return (
          <P
            color={paragraphColor}
            key={p}
            margin={[0, 0, isLast ? 'XS' : 'S']}
            size={paragraphSize}
          >
            <PreventOrphan>{p}</PreventOrphan>
          </P>
        )
      })}
    </StyledGridItem>
    <StyledGridItem spanFromM={5} centered padding={[0, 0, 'M']}>
      <StyledImageWrapperDecorative margin={['XS', 0]} marginFromM="XS">
        <StyledImage
          path={awardImagePaths.signature}
          stretch
          width={500}
          height={112}
        />
      </StyledImageWrapperDecorative>
      <StyledImageWrapperSignature>
        <StyledImage
          path={
            showDecorativeColor
              ? awardImagePaths.decorativeColor
              : awardImagePaths.decorative
          }
          stretch
          width={500}
          height={569}
        />
        {showDecorativeColor && (
          <StyledImage
            path={awardImagePaths.customerCareExcelence}
            stretch
            width={500}
            height={569}
          />
        )}
      </StyledImageWrapperSignature>
    </StyledGridItem>
  </StyledGrid>
)

export default AwardBanner
