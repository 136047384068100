import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Button, Wrapper, Divider, H, P } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'

import {
  CHARITY_AMOUNT_RAISED,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
} from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

import AwardBanner from 'components/AwardBanner'
import BreakpointSwitch from 'components/BreakpointSwitch'
import CharitiesList from 'components/CharitiesList'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import PreventOrphan from 'components/PreventOrphan'
import ThreeEasySteps from 'components/ThreeEasySteps'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'

import ContactUs from 'views/wills/components/ControlLP/ContactUs'
import GetStarted from 'views/wills/components/GetStarted'

import Hero from './components/Hero'
import CallToAction from './components/CTA'
import FreeConsultation from './components/FreeConsultation'
import BespokeService from './components/BespokeService'
import WillOptions from './components/WillOptions'

const StyledHiddenOverflowWrapper = styled(Wrapper)`
  overflow: hidden;
`

const TrustsLandingPage = (): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "2ThP0P6hPEGDdQllR7Pc4Q" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <DefaultLayout
      title="Specialist will writers from the comfort of home"
      description={`Protect your family with a professionally written will from the National Will Writing Firm of the Year. A bespoke service at a fraction of the cost. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot.`}
      product={PRODUCTS.WILLS}
      headerColor={COLOR.WHITE}
      footerShowSelectedProductLinks={false}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    >
      <Wrapper background={COLOR.WHITE}>
        <Wrapper container containerPaddingBottom={0}>
          <Wrapper margin={[0, 0, 'XL']} marginFromL={[0, 0, '92px']}>
            <Hero
              title="Protect your family with a professionally written will"
              subtitle="Get peace of mind from the comfort of home with the UK’s most trusted will writers."
              cta={
                <CallToAction size="L" trackingId="hero-cta">
                  Book your free consultation
                </CallToAction>
              }
            />
          </Wrapper>

          <StyledHiddenOverflowWrapper>
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              belowComponent={<TrustpilotReviews showTitle widget="CAROUSEL" />}
              aboveComponent={<TrustpilotReviews showTitle />}
            />
          </StyledHiddenOverflowWrapper>
        </Wrapper>

        <Wrapper paddingFromL={['S', 0, 'XXL']}>
          <AwardBanner
            title="Will Writing Firm of the Year, 4 years in a row"
            paragraphs={[
              'Since 2016 our specialist team of will writers have written thousands of wills for people in every situation.',
              'We’ve won industry awards for the quality of our work and we’re named a ‘Top low-cost will writing service’ by Money Saving Expert.',
            ]}
            paragraphSize="L"
            paragraphColor={COLOR.GREY.DARK}
            showDecorativeColor
          />
        </Wrapper>

        <Wrapper>
          <WillOptions />
        </Wrapper>

        <StyledHiddenOverflowWrapper background={COLOR.ACCENT.PRIMARY_20}>
          <BespokeService
            cta={
              <CallToAction trackingId="bespoke-service">
                Book a free consultation
              </CallToAction>
            }
          />
        </StyledHiddenOverflowWrapper>

        <ThreeEasySteps
          background={COLOR.WHITE}
          headingMaxWidthInColumns={7}
          ctaPrompt="Get professional support to write your will"
          cta={
            <CallToAction trackingId="why-write-with-a-professional">
              Book your free consultation
            </CallToAction>
          }
          steps={[
            {
              imagePath: 'illustrations/blob-with-house',
              imageWidth: 200,
              imageWidthFromL: 200,
              opticalAdjustment: { x: '4%' },
              heading: 'Safeguard your family’s financial future',
              text: 'Discuss your options with your personal will specialist. You can choose to include a trust in your will so your loved ones are protected and take advantage of the available inheritance tax benefits',
            },
            {
              imagePath: 'illustrations/executor-of-will_zodbff',
              imageWidth: 175,
              imageWidthFromL: 163,
              heading: 'Make sure your children get their fair share',
              opticalAdjustment: { x: '-14%' },
              text: 'Even if your spouse remarries or makes a new will after you’ve died, a will specialist can set up a trust to protect your share of your property for your beneficiaries. It cannot pass to a new spouse or their family.',
            },
            {
              imagePath: 'illustrations/turtle-house-cactus-yellow',
              imageWidth: 175,
              imageWidthFromL: 150,
              opticalAdjustment: { x: '-15%' },
              heading: 'Avoid family disputes',
              text: 'Writing your will with a specialist helps to avoid common mistakes which could be stressful or costly for your loved ones. We pride ourselves on being jargon-free, and always explain how we’re protecting your assets.',
            },
          ]}
          title="Why you should write your will with a professional"
        />

        <Wrapper background={COLOR.ACCENT.PRIMARY_20}>
          <FreeConsultation
            cta={
              <CallToAction size="L" trackingId="free-consultation">
                Book your free consultation
              </CallToAction>
            }
          />
        </Wrapper>

        <Wrapper container>
          <FrequentlyAskedQuestions
            title="Common questions about writing a will"
            questions={faqSection.questions}
            customAmount={4}
            selectedProduct={PRODUCTS.WILLS}
            showCallUsTodayCta
            customCta={
              <Wrapper margin={['L', 0, 0]}>
                <ContactUs />
              </Wrapper>
            }
          />
        </Wrapper>

        <Divider container />

        <Wrapper container>
          <H size="L" decorative>
            <PreventOrphan>Our charity partners</PreventOrphan>
          </H>
          <P maxWidthInColumns={7}>
            Our generous customers have pledged {CHARITY_AMOUNT_RAISED} to our
            charity partners by leaving a gift in their will; a legacy that will
            have an impact long into the future.
          </P>

          <Wrapper
            background={COLOR.BACKGROUND.FOG}
            bordered
            borderRadius="M"
            padding={['M', 0]}
            margin={['L', 0, 0]}
          >
            <CharitiesList withMoreCharitiesText />
          </Wrapper>
        </Wrapper>

        <GetStarted
          heading="Ready to start writing your will?"
          cta={
            <CallToAction tag={Button.White} trackingId="footer">
              Book your free consultation
            </CallToAction>
          }
        />
      </Wrapper>
    </DefaultLayout>
  )
}

export default TrustsLandingPage
