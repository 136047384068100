import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Grid, H, P, Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import Avatar from 'components/Avatar'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'

type FreeConsultationProps = {
  cta: React.ReactNode
}

const StyledWrapper = styled(Wrapper)`
  ${screenMin.l`
    padding-bottom: 120px;
    padding-top: 120px;
  `}
`

const StyledQuote = styled(Wrapper)``

const StyledQuoteBox = styled(Wrapper)`
  border-radius: 16px;
`

const StyledHeading = styled(H)`
  text-wrap: balance;
`

const StyledGetStartedPrompt = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const FreeConsultation = ({
  cta,
}: FreeConsultationProps): React.ReactElement => {
  const { author } = useStaticQuery(graphql`
    query {
      author: contentfulTeamMember(slug: { eq: "adele-davies" }) {
        id
        name
        title
        image {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledWrapper container>
      <Grid>
        <Grid.Item span={12} spanFromM={6} spanFromL={6}>
          <StyledHeading size="M" decorative>
            “There’s no such thing as a one-size-fits-all will”
          </StyledHeading>
          <P color={COLOR.BLACK} margin={[0, 0, GTR.M]}>
            Our will writers like Adele have written thousands of wills, and no
            two are the same. That’s why they take their time to understand the
            wishes of every client.
          </P>
          <TrustpilotSummary />
          <StyledGetStartedPrompt strong margin={[GTR.M, 0, GTR.XS]}>
            Get started today
          </StyledGetStartedPrompt>
          {cta}
        </Grid.Item>
        <Grid.Item
          span={12}
          spanFromM={5}
          startColumnFromM={8}
          spanFromXL={4}
          startColumnFromL={8}
        >
          <StyledQuoteBox background={COLOR.WHITE} padding={[GTR.M]}>
            <Avatar
              imageSrc={author?.image?.file?.url}
              size={112}
              alt={author.name}
            />
            <Wrapper margin={[GTR.M, 0]}>
              <P strong margin="0">
                {author.name}
              </P>
              <P strong margin="0">
                {author.title}, Farewill
              </P>
            </Wrapper>
            <StyledQuote margin="0" tag="blockquote">
              <P color={COLOR.BLACK}>
                “I’ve worked in the legal industry for more than 19 years, with
                qualifications from Chartered Institute of Legal Executives and
                Society of Trust and Estate Practitioners.”
              </P>
            </StyledQuote>
          </StyledQuoteBox>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

export default FreeConsultation
