import React from 'react'
import styled from 'styled-components'
import { P, TickIcon, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'

import {
  TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  TELEPHONE_WILL_PRICE_IN_POUNDS,
  TELEPHONE_WILL_TRUST_PRICE_IN_POUNDS,
} from 'config'
import type { WillOption } from 'views/wills/components/WillOptions/types'
import WillOptions from 'views/wills/components/WillOptions'

import CallToAction from '../CTA'

export const SPECIALIST_WILL: WillOption = {
  title: 'Specialist Will',
  singlePrice: TELEPHONE_WILL_PRICE_IN_POUNDS,
  couplesPrice: TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  bullets: [
    {
      icon: <TickIcon />,
      text: 'Discuss your wishes over the phone with a will specialist',
    },
    {
      icon: <TickIcon />,
      text: 'Great for getting extra support where you need it',
    },
    {
      icon: <TickIcon />,
      text: 'Professionally printed will, delivered to your door',
    },
  ],
  cta: () => (
    <CallToAction trackingId="will-option-specialist-will">
      Book a free consultation
    </CallToAction>
  ),
}

export const WILL_WITH_TRUST: WillOption = {
  title: 'Wills with a trust',
  singlePrice:
    TELEPHONE_WILL_PRICE_IN_POUNDS + TELEPHONE_WILL_TRUST_PRICE_IN_POUNDS,
  couplesPrice:
    TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS +
    2 * TELEPHONE_WILL_TRUST_PRICE_IN_POUNDS,
  bullets: [
    {
      icon: <TickIcon />,
      text: 'Discuss your wishes over the phone with a will specialist',
    },
    {
      icon: <TickIcon />,
      text: 'Great for getting extra support where you need it',
    },
    {
      icon: <TickIcon />,
      text: 'Professionally printed will, delivered to your door',
    },
    {
      icon: <TickIcon />,
      text: 'Increased asset protection and control',
    },
    {
      icon: <TickIcon />,
      text: 'Include business assets or discretionary trusts',
    },
  ],
  cta: () => (
    <CallToAction trackingId="will-option-will-with-trust">
      Book a free consultation
    </CallToAction>
  ),
}

const StyledBreak = styled.br`
  ${screenMax.m`
    display: none;
  `}
`

const SolicitorWillOptions = (): React.ReactElement => (
  <WillOptions
    edgeDirection="none"
    options={[SPECIALIST_WILL, WILL_WITH_TRUST]}
    footer={
      <Wrapper centered margin={[GTR.XL, 'auto', 0]} maxWidthInColumns={7}>
        <P color={COLOR.BLACK} size="L" strong>
          Choosing the right will for your situation
        </P>
        <P color={COLOR.BLACK}>
          Not sure which will you need? Talk through your circumstances when we
          call. <StyledBreak />
          We’ll always give the final cost of your will before we start work.
        </P>
      </Wrapper>
    }
  />
)

export default SolicitorWillOptions
