import React from 'react'
import styled, { css } from 'styled-components'

import { Divider, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { screen, screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { BREAKPOINT, GTR } from '@farewill/ui/tokens'

import TelephoneNumber from 'components/TelephoneNumber'
import PhoneLineOpenIndicator from 'components/PhoneLineOpenIndicator'
import BreakpointSwitch from 'components/BreakpointSwitch'

type BespokeServiceProps = {
  cta?: React.ReactNode
  imagePath?: string
  title?: string
  subtitle?: string
}

const MOBILE_IMAGE_ASPECT_RATIO = 1.176

const DESKTOP_IMAGE_STYLES = css`
  bottom: var(--image-wrapper-overscan-bottom);
  height: calc(
    100% - var(--image-wrapper-overscan-bottom) -
      var(--image-wrapper-overscan-top)
  );
  left: -144px;
  width: auto;
`

const StyledImage = styled(Image)`
  bottom: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  width: 100%;

  ${screen.m`
    ${DESKTOP_IMAGE_STYLES}
  `}

  ${screen.l`
    ${DESKTOP_IMAGE_STYLES}
  `}
`

const StyledSvg = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`

const WidescreenBackgroundBlob = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1 1"
    fill="none"
    className={className}
    data-name="widescreen-background-blob"
  >
    <path
      d="M0.934709 0.230733C0.825211 0.082454 0.682019 -0.000515334 0.50198 2.73884e-05C0.378841 -0.00128537 0.258613 0.0446323 0.163497 0.129302C0.0759103 0.205717 0.0193978 0.314825 0.00602373 0.433333C0.00446489 0.447888 -0.00436096 0.46453 0.00270783 0.47669C0.0825723 0.619543 0.0896507 0.803332 0.226407 0.91586C0.356825 1.02324 0.51677 1.02977 0.650893 0.924317C0.701866 0.882582 0.754779 0.843601 0.809422 0.807527C1.00485 0.681816 1.05606 0.435393 0.934709 0.230733Z"
      fill="#FFDF4E"
    />
  </svg>
)

const StyledWidescreenBackgroundBlob = styled(WidescreenBackgroundBlob)`
  aspect-ratio: 1.1740976645;
  position: relative;
  left: 5.5%;
  width: 73%;
`

const MobileMask = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="1"
    fill="none"
    data-mask-variant="mobile"
  >
    <defs>
      <clipPath
        id="bespoke-service-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path
          d="M0 1H1L1 0.0755554C0.842225 0.00978374 0.666944 -0.00880997 0.48351 0.0036096C0.388209 0.00580548 0.294646 0.0277047 0.209679 0.0677034C0.128347 0.105991 0.0568636 0.159934 0 0.225861V1Z"
          fill="#FBEBA2"
        />
      </clipPath>
    </defs>
  </StyledSvg>
)

const BlobMask = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="1"
    fill="none"
    data-mask-variant="desktop"
  >
    <defs>
      <clipPath
        id="bespoke-service-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path
          d="M0.495649 7.32597e-07H1V0.560518C1 0.560636 1 0.560754 1 0.560872V0.968302H0.60241C0.577093 0.968184 0.551595 0.965168 0.52626 0.958956C0.370517 0.917956 0.312449 0.8945 0.186903 0.773446C0.0918046 0.696276 0.0280801 0.587103 0.00768475 0.466412C-0.00657174 0.400087 -0.000906606 0.331059 0.0239671 0.268006C0.0488409 0.204953 0.0918125 0.15069 0.147481 0.112039C0.233186 0.0533193 0.331952 0.0164068 0.435219 0.00450138C0.455272 0.00145848 0.475474 -3.79924e-05 0.495649 7.32597e-07Z"
          fill="#FBEBA2"
        />
      </clipPath>
    </defs>
  </StyledSvg>
)

const WidescreenBlobMask = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="1"
    fill="none"
    data-mask-variant="widescreen"
  >
    <defs>
      <clipPath
        id="bespoke-service-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path
          d="M0.472714 0.996253C0.355715 1.00736 0.239335 0.967898 0.145409 0.88528C0.0916781 0.842253 0.0499844 0.781499 0.0255697 0.710657C0.00115496 0.639815 -0.00489084 0.562048 0.00819294 0.487131C0.0288553 0.372433 0.0729952 0.265359 0.136787 0.175188C0.171616 0.122214 0.216079 0.0790584 0.266917 0.0488863C0.317755 0.0187142 0.373679 0.0022895 0.430592 0.000815323C0.610974 -0.0140884 0.777947 0.0347308 0.906406 0.201908C1.05858 0.40191 1.02479 0.752087 0.830823 0.883279C0.695647 0.97115 0.639848 0.997246 0.472714 0.996253Z"
          fill="#FBEBA2"
        />
      </clipPath>
    </defs>
  </StyledSvg>
)

/**
 * [1] Fix image showing beyond element bounds in Firefox
 */
const StyledImageWrapper = styled(Wrapper)`
  --image-wrapper-overscan-bottom: 100px;
  --image-wrapper-overscan-top: 60px;
  clip-path: url(#bespoke-service-clip-path);
  overflow: hidden; // [1]

  ${screenMax.s`
    aspect-ratio: ${MOBILE_IMAGE_ASPECT_RATIO};
    bottom: 0;
    position: absolute;
    left: 0;
    min-width: 312px;
    width: 100%;
  `}

  ${screenMin.m`
    bottom: calc(-1 * var(--image-wrapper-overscan-bottom));
    position: absolute;
    left: calc(50vw + 48px);
    top: calc(-1 * var(--image-wrapper-overscan-top));
    width: 750px;
  `}

  ${screenMin.xl`
    aspect-ratio: 1.1740976645;
    bottom: 0;
    position: relative;
    left: auto;
    top: 0;
    width: 400px;
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  position: relative;

  ${screenMax.l`
    min-height: 610px;
  `}
`

const StyledLeftGridItem = styled(Grid.Item)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledRightGridItem = styled(Grid.Item)`
  ${screenMin.l`
    display: grid;
    place-items: center;

    & > * {
      grid-area: 1 / 1;
    }
  `}
`

const CtaWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${GTR.M};

  & > * {
    flex-shrink: 0;
  }

  ${screenMin.l`
    align-items: center;
    flex-direction: row;
  `}
`

const StyledDivider = styled(Divider)`
  height: calc(100% + ${GTR.S});
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  margin-bottom: ${GTR.XS};
  width: min-content;
`

const StyledPhoneLineOpenIndicator = styled(PhoneLineOpenIndicator)`
  margin-left: 7px; // Optical nudge to align with telephone icon above
`

const AspectRatioWrapper = styled(Wrapper)`
  ${screenMax.s`
    aspect-ratio: ${MOBILE_IMAGE_ASPECT_RATIO};
    width: 100%;
  `}
`

const BespokeService = ({
  cta,
  imagePath = 'com-assets/editorial-images/conor-on-the-phone',
  title = 'A bespoke service at a fraction of the cost',
  subtitle = 'With Farewill, your will consultation takes place over the phone. As we don’t have high street shops, we pass on our savings to you.',
}: BespokeServiceProps): React.ReactElement => (
  <StyledWrapper container>
    <Grid>
      <StyledLeftGridItem spanFromM={6}>
        <H decorative size="L">
          {title}
        </H>
        <P size="L" marginFromM={[0, 0, 'XL']}>
          {subtitle}
        </P>
        <CtaWrapper noTrim>
          {cta}
          <BreakpointSwitch
            breakAt={BREAKPOINT.M}
            aboveComponent={<StyledDivider vertical />}
          />
          <Wrapper margin={[0, 0, 'M']} marginFromM="0">
            <StyledTelephoneNumber />
            <StyledPhoneLineOpenIndicator text="We’re open - call us today" />
          </Wrapper>
        </CtaWrapper>
      </StyledLeftGridItem>
      <StyledRightGridItem span={12} spanFromM={6}>
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          belowComponent={<MobileMask />}
          aboveComponent={
            <BreakpointSwitch
              breakAt={BREAKPOINT.L}
              belowComponent={<BlobMask />}
              aboveComponent={<WidescreenBlobMask />}
            />
          }
        />
        <BreakpointSwitch
          breakAt={BREAKPOINT.L}
          aboveComponent={<StyledWidescreenBackgroundBlob />}
        />
        <AspectRatioWrapper>
          <StyledImageWrapper>
            <StyledImage
              alt="Friendly Farewill employee taking a customer call"
              ext="jpg"
              // Work around bug in FF + Safari where loading=lazy inside
              // clip-path results in the image not loading…
              loading="eager"
              path={imagePath}
              width={1000}
            />
          </StyledImageWrapper>
        </AspectRatioWrapper>
      </StyledRightGridItem>
    </Grid>
  </StyledWrapper>
)

export default BespokeService
