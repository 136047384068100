import React from 'react'
import styled from 'styled-components'

import { Grid, Image, H, P, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

type HeroProps = { cta: React.ReactNode; title: string; subtitle: string }

const StyledHeading = styled(H)`
  text-wrap: balance;
`

const StyledImage = styled(Image)`
  height: 100%;
  object-fit: cover;
  width: auto;
  position: relative;
  left: -10%;
`

const StyledOuterImageWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${screenMax.m`
    max-width: 480px;
  `}
`

const StyledImageWrapper = styled(Wrapper)`
  aspect-ratio: 1.176;
  clip-path: url(#blob-clip-path);
  overflow: hidden;
  position: relative;
  max-width: 70%;
  width: 100%;
`

const StyledSvg = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`

const BlobMask = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="1"
    fill="none"
  >
    <defs>
      <clipPath id="blob-clip-path" clipPathUnits="objectBoundingBox">
        <path
          d="M0.472714 0.996253C0.355715 1.00736 0.239335 0.967898 0.145409 0.88528C0.0916781 0.842253 0.0499844 0.781499 0.0255697 0.710657C0.00115496 0.639815 -0.00489084 0.562048 0.00819294 0.487131C0.0288553 0.372433 0.0729952 0.265359 0.136787 0.175188C0.171616 0.122214 0.216079 0.0790584 0.266917 0.0488863C0.317755 0.0187142 0.373679 0.0022895 0.430592 0.000815323C0.610974 -0.0140884 0.777947 0.0347308 0.906406 0.201908C1.05858 0.40191 1.02479 0.752087 0.830823 0.883279C0.695647 0.97115 0.639848 0.997246 0.472714 0.996253Z"
          fill="#FBEBA2"
        />
      </clipPath>
    </defs>
  </StyledSvg>
)

const DecorativeBlob = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="433"
    height="423"
    viewBox="0 0 433 423"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M404.362 98.235C356.993 35.7398 295.047 0.770512 217.162 0.999243C163.892 0.445935 111.881 19.799 70.7334 55.4847C32.843 87.6917 8.39552 133.678 2.60986 183.626C1.9355 189.76 -1.88259 196.774 1.17539 201.899C35.725 262.108 38.7871 339.57 97.9483 386.997C154.368 432.255 223.56 435.007 281.582 390.561C303.633 372.971 326.523 356.542 350.162 341.338C434.706 288.354 456.857 184.494 404.362 98.235Z"
      fill="#FFDF4E"
    />
  </svg>
)

const StyledDecorativeBlob = styled(DecorativeBlob)`
  height: auto%;
  position: absolute;
  left: 56%;
  transform: translate(-50%, -50%);
  top: 51%;
  width: 66%;
`

const StyledGridItem = styled(Grid.Item)`
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Hero = ({ cta, subtitle, title }: HeroProps): React.ReactElement => (
  <Grid>
    <Grid.Item span={12} spanFromL={6} noTrim>
      <StyledHeading size="XL" decorative margin={['XS', 0, 'S']}>
        {title}
      </StyledHeading>
      <P size="L">{subtitle}</P>
      {cta}
    </Grid.Item>
    <StyledGridItem startColumnFromL={7} noTrim>
      <StyledOuterImageWrapper
        margin={['M', 0, 0]}
        marginFromM={['XL', 0, 0]}
        marginFromL={0}
      >
        <StyledDecorativeBlob />
        <StyledImageWrapper>
          <BlobMask />
          <StyledImage
            alt="Grandparent holding a smiling grandchild in an Autumn park"
            ext="jpg"
            // Work around bug in FF + Safari where loading=lazy inside
            // clip-path results in the image not loading…
            loading="eager"
            path="com-assets/editorial-images/parent-with-grandchild-in-autumn-park"
            width={600}
          />
        </StyledImageWrapper>
      </StyledOuterImageWrapper>
    </StyledGridItem>
  </Grid>
)

export default Hero
